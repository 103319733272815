<template>
    <v-app>
        <v-main :style="($vuetify.theme.dark)?'background:#121212':'background:rgb(220 223 224)'">
            <div v-if="form">
                <v-toolbar dense flat class="light-blue darken-4" dark>
                    <v-toolbar-title>Laboratorio certificado por {{ips.find(e => e.VERSION == $route.params.entidad).NOMBRE}}</v-toolbar-title>
                </v-toolbar>
                <v-card class="pa-2" outlined tile>
                    <v-container>

                        <v-row class="caption" no-gutters justify="center">
                            <v-col cols="12" sm="12" md="2">
                                <label class="primary--text">FECHA: </label>

                                <label class="font-weight-black">{{ form.cifaFecha}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="2" v-if="form.tblCitaCita">
                                <label class="primary--text">PACIENTE: </label>

                                <label class="font-weight-black" v-if="form.tblCitaCita.tblConfPersonaspacientes.length > 0">{{ form.tblCitaCita.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnNombre1 + " " +form.tblCitaCita.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnApellido1 }}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="4">
                                <v-btn outlined class="mx-2" small dark color="info" v-if="(version != 'patologia'|| form.tblCitaCita.citaTipo == 0 )" @click="pdf(null)">
                                    <v-icon dark>mdi-file-pdf-box</v-icon> Pdf
                                </v-btn>
                            </v-col>
                        </v-row>

                    </v-container>
                </v-card>
                <v-card class="pa-2 " color="grey lighten-2" outlined tile>
                    <v-container>
                        <v-row justify="space-around">
                            <v-col cols="12" sm="12" md="12" style="min-heigth:80px" v-for="(item, i) in form.tblCitaOrdenes" :key="i">
                                <v-sheet min-height="100%" width="100%">
                                    <v-container fluid class="pa-0">
                                        <v-row align="center" no-gutters class="text-center" style="min-height:80px">
                                            <v-col cols="12" sm="12" md="1">
                                                <span>
                                                    <v-icon>mdi-format-color-fill</v-icon>
                                                </span>
                                            </v-col>
                                            <v-divider light class="mx-1" vertical></v-divider>
                                            <v-col cols="12" sm="12" md="8">
                                                <span>{{item.tblConfCups[0].cocuNombre}}</span>
                                            </v-col>
                                            <v-btn icon class="mx-2" x-small dark color="info" v-if="version == 'patologia' &&  form.tblCitaCita.citaTipo != 0" @click="pdf(item)">
                                                <v-icon dark>mdi-file-pdf-box</v-icon>
                                            </v-btn>
                                        </v-row>
                                    </v-container>
                                </v-sheet>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
                <v-snackbar v-model="notificacion.show" color="warnning" :multi-line="true">
                    {{ notificacion.mensaje }}
                </v-snackbar>
            </div>
        </v-main>
    </v-app>
</template>

<script>
import {
  documentos
}
from "../utils/documentos";

import jsPDF from 'jspdf';
import 'jspdf-autotable';
import QRCode from 'qrcode'
export default {
  name: 'Entregapanel',
  components: {

  },
  props: {
    Id: Number
  },
  data() {
    return {
      tab: "tab-1",
      dialog: false,
      url: "cita/citas/",
      facturado: false,
      token: this.$cookies.get("token"),
      user: this.$cookies.get("user"),
      form: null,
      version: this.$cookies.get("TIPO"),
      menu: false,
      actual: 0,
      cantidad: 0,
      cireId: null,
      cantidadPendiente: 0,
      meses: ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO", "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"],
      notificacion: {
        show: false,
        mensaje: ''
      },
      secciones: [],
      campos: [],
      diagnosticos: [],
      Historia: [],
      historial: [],
      ips: []

    }
  },

  created: async function() {
    if (this.$route.params.id || this.Id) {
      await this.$config.cargar();
      this.ips = this.$config.listaIPS;
      this.$cookies.set("ENTIDAD", this.$route.params.entidad);
      let ips = this.ips.find(e => e.VERSION == this.$route.params.entidad);
      this.version = ips.TIPO;
      this.$cookies.set("TIPO", ips.TIPO);
      this.$store.commit('setCargaDatos', true);
      this.form = await this.$apiService.index("cita/facturaciones/pdf/" + (this.$route.params.id ? this.$route.params.id : this.Id)).then(data => data);
      this.form.tblCitaOrdenes = this.form.tblCitaOrdenes.filter(e => e.orden.comaNombrecorto == 'TP_ORD_LAB');
      this.isNew = false;
      this.$store.commit('setCargaDatos', false);
    }
  },

  methods: {
    allowedDates: val => new Date(val.replace(/-+/g, '/')).getDay() != 0 && new Date(val.replace(/-+/g, '/')).getDay() != 6,
    currentDate(date) {
      const fecha = date;
      if (this.allowedDates(fecha.yyyymmdd())) {
        return fecha.yyyymmdd()
      } else {
        fecha.setDate(fecha.getDate() + 1);
        return this.currentDate(fecha);

      }
    },

    async pdf(item) {
      await this.dibujarH(item);
    },
    async dibujarH(item) {
      this.Historia = [];
      this.secciones = [];
      this.campos = [];
      this.diagnosticos = [];
      let datosPaciente = null;
      if (this.version == 'patologia' && this.form.tblCitaCita.citaTipo != 0) {
        datosPaciente = await this.$apiService.index("cita/citas/view/factura/" + this.form.citaId).then(data => data);
        this.diagnosticos = await this.$apiService.index("cita/asistenciales/atender/index/" + this.form.cifaId + "/diagnostico").then(data => data);
        this.secciones = await this.$apiService.index("sistema/formulariosecciones/secciones/" + item.cocuId + "?LABORATORIO").then(data => data);
        this.Historia = await this.$apiService.index("sistema/formulariosfacturaciones/index/" + item.ciorId + "?tipo=orden").then(data => data);
      } else {
        datosPaciente = await this.$apiService.index("cita/citas/view/factura/" + this.form.citaId).then(data => data);
        this.diagnosticos = await this.$apiService.index("cita/asistenciales/atender/index/" + this.form.cifaId + "/diagnostico").then(data => data);
        datosPaciente.tblCitaFacturaciones[0].tblCitaOrdenes = datosPaciente.tblCitaFacturaciones[0].tblCitaOrdenes.filter(e => e.orden.comaNombrecorto == 'TP_ORD_LAB');
        for (let index = 0; index < datosPaciente.tblCitaFacturaciones[0].tblCitaOrdenes.length; index++) {
          let orden = datosPaciente.tblCitaFacturaciones[0].tblCitaOrdenes[index];
          let s = await this.$apiService.index("sistema/formulariosecciones/secciones/" + orden.cocuId + "?LABORATORIO").then(data => data);
          this.secciones.push({
            ciorId: orden.ciorId,
            secciones: s
          });
          let h = await this.$apiService.index("sistema/formulariosfacturaciones/index/" + orden.ciorId + "?tipo=orden").then(data => data);
          this.Historia.push({
            ciorId: orden.ciorId,
            Historia: h
          });

        }
      }

      if (this.Historia.length > 0) {
        if (this.version == 'patologia' && this.form.tblCitaCita.citaTipo != 0) {
          this.secciones.forEach((seccion) => {
            if (seccion.campos.length > 0) {
              seccion.campos.sort((a, b) => a.foseOrden - b.foseOrden);
              seccion.campos.forEach(campo => {
                if (this.Historia.find(e => e.foseId == campo.foseId) == undefined) {

                  this.Historia.push({
                    ciffId: null,

                    ciffValor: null,

                    foseId: campo.foseId
                  });
                }
              });
            }

          });
        }
        if (typeof window !== 'undefined') {
          let documento = new documentos(this.$config.listaIPS,this.$config.convenciones,null, this.$cookies.get("ENTIDAD"));
          let pdf = new jsPDF('p', 'pt', 'letter');
          var firma = '';
          if (this.$cookies.get("TIPO") != 'patologia' || datosPaciente.citaTipo == 0) {
            if (this.Historia.find(e => e.Historia.length).Historia[0].empleado) {
              let datafirma = await this.$apiService.index(`sistema/personas/getFirmaPaciente?copeIdentificacion=${this.datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeId}?copeIdentificacion=${this.Historia.find(e => e.Historia.length).Historia[0].empleado.tblConfPersonasnaturale.tblConfPersona.copeIdentificacion}`).then(data => data);
              if (datafirma.mensaje == undefined && datafirma.mensaje != 'No data') {
                firma = 'data:image/png;base64,' + datafirma;
              } else {
                firma = (this.$cookies.get("ENTIDAD") != 'talatshi' ? ('/' + this.Historia.find(e => e.Historia.length).Historia[0].empleado.tblConfPersonasnaturale.tblConfPersona.copeFirma) : '/firmas/talatshi/RM 441178.png');
              }
            }
            QRCode.toDataURL("http://149.56.134.140:8004/laboratorio-certificado/" + this.$route.params.entidad +"/"+(this.$route.params.id ? this.$route.params.id : this.Id)).then(url => {
                documento.getLaboratorio(pdf, datosPaciente, this.Historia, this.secciones, this.diagnosticos, url, firma);
              })
              .catch(err => {
                console.error(err)
              });
          } else {
            
            if (this.$cookies.get("TIPO") != 'patologia' || this.secciones.find(a => a.campos.find(b => b.foseCodigo == 'F_DEFAULT_0')) == undefined) {
              if (this.secciones.find(e => e.campos.find(a => a.tblConfFormularioseccione.foseCodigo == 'F_CITO_S6_13'))) {
                let valor = this.Historia.find(e => e.foseId == this.secciones.find(e => e.campos.find(a => a.tblConfFormularioseccione.foseCodigo == 'F_CITO_S6_13')).campos.find(a => a.tblConfFormularioseccione.foseCodigo == 'F_CITO_S6_13').foseId);
                if (valor.ciffValor) {
                  let datafirma = await this.$apiService.index(`sistema/personas/getFirmaPaciente?copeIdentificacion=${this.datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeId}?copeIdentificacion=${valor.ciffValor}`).then(data => data);
                  if (datafirma.mensaje == undefined && datafirma.mensaje != 'No data') {
                    firma = 'data:image/png;base64,' + datafirma;
                  } else {
                    firma = '/firmas/' + this.entidad + '/' + valor.ciffValor + '.png';
                  }
                }
              } else {
                if (this.Historia[0].empleado && this.Historia[0].empleado.tblConfPersonasnaturale.tblConfPersona.copeFirma != null) {
                  let datafirma = await this.$apiService.index(`sistema/personas/getFirmaPaciente?copeIdentificacion=${this.datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeId}?copeIdentificacion=${this.Historia[0].empleado.tblConfPersonasnaturale.tblConfPersona.copeIdentificacion}`).then(data => data);
                  if (datafirma.mensaje == undefined && datafirma.mensaje != 'No data') {
                    firma = 'data:image/png;base64,' + datafirma;
                  } else {
                    firma = '/' + this.Historia[0].empleado.tblConfPersonasnaturale.tblConfPersona.copeFirma;
                  }
                }
              }
            } else {
              if (this.$cookies.get("TIPO") == 'patologia' && this.$cookies.get("ENTIDAD") == 'patologia') {
                let valor = this.Historia.find(e => e.foseId == this.secciones.find(e => e.campos.find(a => a.foseCodigo == 'F_DEFAULT_6')).campos.find(a => a.foseCodigo == 'F_DEFAULT_6').foseId);
                if (valor.ciffValor) {
                  firma = '/firmas/patologia/' + valor.ciffValor.split(',')[0] + '.jpg';
                }
              }

              if (this.$cookies.get("TIPO") == 'patologia' && this.$cookies.get("ENTIDAD") == 'patologia2') {
                let valor = this.Historia.find(e => e.foseId == this.secciones.find(e => e.campos.find(a => a.foseCodigo == 'F_DEFAULT_6')).campos.find(a => a.foseCodigo == 'F_DEFAULT_6').foseId);
                if (valor.ciffValor) {
                  let datafirma = await this.$apiService.index(`sistema/personas/getFirmaPaciente?copeIdentificacion=${this.datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeId}?copeIdentificacion=${valor.ciffValor.split(',')[0]}`).then(data => data);
                  if (datafirma.mensaje == undefined && datafirma.mensaje != 'No data') {
                    firma = 'data:image/png;base64,' + datafirma;
                  } else {
                    firma = '/firmas/patologia2/' + valor.ciffValor.split(',')[0] + '.jpg';
                  }
                }
              }
            }
            documento.getDocumento(pdf, datosPaciente, this.Historia, this.secciones, this.diagnosticos, [], '', firma);
          }

        }

      } else {
        this.$swal.fire({
          title: "Historia no tramitada!",
          text: "primero debe ser llenado la historia",
          icon: "error"
        });
      }

    },

  }
}

</script>
